<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 49 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Face</title>
    <path
      d="M18.5 23.5C17.12 23.5 16 24.62 16 26C16 27.38 17.12 28.5 18.5 28.5C19.88 28.5 21 27.38 21 26C21 24.62 19.88 23.5 18.5 23.5ZM30.5 23.5C29.12 23.5 28 24.62 28 26C28 27.38 29.12 28.5 30.5 28.5C31.88 28.5 33 27.38 33 26C33 24.62 31.88 23.5 30.5 23.5ZM24.5 4C13.46 4 4.5 12.96 4.5 24C4.5 35.04 13.46 44 24.5 44C35.54 44 44.5 35.04 44.5 24C44.5 12.96 35.54 4 24.5 4ZM24.5 40C15.68 40 8.5 32.82 8.5 24C8.5 23.42 8.54 22.84 8.6 22.28C13.32 20.18 17.06 16.32 19.02 11.54C22.64 16.66 28.6 20 35.34 20C36.9 20 38.4 19.82 39.84 19.48C40.26 20.9 40.5 22.42 40.5 24C40.5 32.82 33.32 40 24.5 40Z"
      fill="#E9EBED"
    />
    <path
      d="M18.5 23.5C17.12 23.5 16 24.62 16 26C16 27.38 17.12 28.5 18.5 28.5C19.88 28.5 21 27.38 21 26C21 24.62 19.88 23.5 18.5 23.5ZM30.5 23.5C29.12 23.5 28 24.62 28 26C28 27.38 29.12 28.5 30.5 28.5C31.88 28.5 33 27.38 33 26C33 24.62 31.88 23.5 30.5 23.5ZM24.5 4C13.46 4 4.5 12.96 4.5 24C4.5 35.04 13.46 44 24.5 44C35.54 44 44.5 35.04 44.5 24C44.5 12.96 35.54 4 24.5 4ZM24.5 40C15.68 40 8.5 32.82 8.5 24C8.5 23.42 8.54 22.84 8.6 22.28C13.32 20.18 17.06 16.32 19.02 11.54C22.64 16.66 28.6 20 35.34 20C36.9 20 38.4 19.82 39.84 19.48C40.26 20.9 40.5 22.42 40.5 24C40.5 32.82 33.32 40 24.5 40Z"
      fill="url(#paint0_linear_2285_10273)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_2285_10273"
        x1="279.889"
        y1="-157.818"
        x2="36.997"
        y2="66.2534"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#6E19F0" />
        <stop
          offset="1"
          stop-color="#6E19F0"
          stop-opacity="0"
        />
      </linearGradient>
    </defs>
  </svg>
</template>
<script>
export default {
  name: 'ic-face',
  props: {
    size: {
      type: [String, Number],
      default: 24,
    },
  },
}
</script>
